<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="状态">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border
                      ref="multipleTable">
                <el-table-column prop="ID" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="r" label="排序" width="80" align="center"></el-table-column>
                <el-table-column prop="name" label="企业名称" align="center"></el-table-column>
                <el-table-column prop="desc" label="简介描述" align="center" width="300"></el-table-column>
                <el-table-column label="品牌logo" align="center" width="120" prop="logo">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img1')" style="height:23px;width:30px;" :class="`img1column${scope.$index}`" :src="scope.row.logo" :preview-src-list="[scope.row.logo]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="品牌背景海报" align="center" width="120" prop="bg_img">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img1')" style="height:23px;width:30px;" :class="`img1column${scope.$index}`" :src="scope.row.bg_img" :preview-src-list="[scope.row.bg_img]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="关联码" prop="code" align="center"></el-table-column>
                <el-table-column label="邀请码" prop="invite_code" align="center"></el-table-column>
                <el-table-column label="邀请码过期时间" prop="invite_code_expire" align="center">
                    <template slot-scope="scope">
                        {{formatDateFn(scope.row.invite_code_expire)}}
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="小程序入口二维码" align="center" width="180" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;"
                                  :class="`column${scope.$index}`"
                                  fit="cover" :src="scope.row.mp_qrcode" :preview-src-list="[scope.row.mp_qrcode]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="250" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-edit" @click="genMpQrcode(scope.$index, scope.row)">
                            生成小程序码
                        </el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize"
                        v-on:refreshPageNum="refreshPageNum"/>
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" class="dialog_box" v-if="addVisible" :destroy-on-close="true"	>
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="企业名称 " prop="name">
                    <el-input v-model="form.name" placeholder="请输入企业名称"></el-input>
                </el-form-item>
                <el-form-item label="企业描述 " prop="desc">
                    <el-input v-model="form.desc" placeholder="请输入企业描述"></el-input>
                </el-form-item>
                <el-form-item label="品牌logo" prop="logo">
                    <el-input v-model="form.logo" placeholder="请输入品牌logo"></el-input>
                    <el-button @click.prevent="choiceImg">选择图片</el-button>
                    <el-upload class="avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload" name="file" :headers="header" style="margin-top: 20px;">
                        <img v-if="form.logo" :src="form.logo" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸256*256像素</span>
                </el-form-item>
                <el-form-item label="品牌背景图" prop="bg_img">
                    <el-input v-model="form.bg_img" placeholder="请输入品牌背景图"></el-input>
                    <el-button @click.prevent="choiceBgImg">选择图片</el-button>
                    <el-upload class="avatar-uploader" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header" style="margin-top: 20px;">
                        <img v-if="form.bg_img" :src="form.bg_img" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸750*750像素</span>
                </el-form-item>
                <el-form-item label="行业属性" prop="industry_cate">
                    <el-select v-model="form.industry_cate" placeholder="请选择行业属性" class="handle-select mr10">
                        <el-option v-for="items in cateGroupList[0].List" :key="items.ID" :label="items.name" :value="items.ID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企业性质" prop="quality_cate">
                    <el-select v-model="form.quality_cate" placeholder="请选择企业性质" class="handle-select mr10">
                        <el-option v-for="items in cateGroupList[1].List" :key="items.ID" :label="items.name" :value="items.ID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="产品服务" prop="service_cate">
                    <el-select v-model="form.service_cate" placeholder="请选择产品服务" class="handle-select mr10">
                        <el-option v-for="items in cateGroupList[2].List" :key="items.ID" :label="items.name" :value="items.ID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="tag" label="关联tag">
                    <el-tag v-for="(item,index) in tagList" :key="item.index" closable :disable-transitions="true" @close="handleClose(index)">{{item.name}}</el-tag>
                    <el-autocomplete ref="saveTagInput" v-if="tagVisible" class="inline-input" v-model="tag" :fetch-suggestions="queryTagSearch" placeholder="输入内容" :trigger-on-focus="false" @select="handleTagSelect"></el-autocomplete>
                    <el-button v-else class="button-new-tag" size="small" @click="showtag">新增标签</el-button>
                </el-form-item>
                <el-form-item label="邀请码 " prop="name">
                    <el-input v-model="form.invite_code" placeholder="请输入企业邀请码"></el-input>
                </el-form-item>
                <el-form-item label="排序值" prop="r">
                    <el-input v-model="form.r" placeholder="请输入排序值"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="邀请码过期时间" prop="state">
                    <el-date-picker v-model="form.invite_code_expire" type="datetime"></el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 删除 -->
        <el-dialog title="是否删除数据" :visible.sync="removeVisible" width="300px" center>
            <div class="del-dialog-cnt">删除后数据无法恢复，是否确认删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="removeVisible = false">取 消</el-button>
                <el-button type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getLogoImg"></Picture>
        </div>


        <div class="img">
            <Picture :ImgVisible="BgImgVisible" @toggleImg="toggleBgImg" @getImg="getBgImg"></Picture>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import Picture from '@/components/Picture';
import Imgupload from '@/components/Imgupload';

import {request} from '@/common/request';
import {deal} from '@/common/main';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
import {formatDate} from "@/common/date.js";

export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Picture,
        Imgupload,
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL: IMAGE_UPLOAD_PRO_URL,
            text: 'image',
            ImgVisible: false,
            BgImgVisible: false,
            header: {},
            defaultImg: require('../../assets/img/zhan.jpeg'),
            imageUrl: '',
            hearder: {},
            menuList: ['企业管理', '企业列表'],
            queryParams: {
                ctime: '',
                etime: '',
                searchName: '',
                page: 1,
                pagesize: 10
            },

            tag: '',
            tagVisible: false,
            tagList: [],
            queryTagParams:{},

            //验证规则
            rules: {
                r: [{
                    required: true,
                    message: '序号不能为空',
                    trigger: 'blur'
                }],
                platform: [{
                    required: true,
                    message: '平台不能为空',
                    trigger: 'blur'
                }],
                image: [
                    {validator: deal.checkImage, trigger: ['blur', 'change'], required: true,}
                ],
                url: [
                    {validator: deal.checkUrl, trigger: ['blur', 'change'], required: true,}
                ],
                time: [{
                    required: true,
                    message: '起止时间不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
            },
            loading: false,
            pageInfo: {},
            addVisible: false,
            removeVisible: false,
            confirmVisible: false,
            confirmContent: '',
            title: '加载中',
            mode: 'add',
            form: {
                desc:"",
                state: 1,
                logo:"",
                bg_img:"",
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            cateGroupList:{}

        };
    },
    created() {
        this.hearder.token = localStorage.getItem('token');
        this.hearder.platform = 'admin';
        this.loadData()
        this.getCateGroupList()
    },
    //图片上传
    methods: {
        getCateGroupList(){
            // 获取banner列表
            request.get('/enterprise/cate/group/list', this.queryParams).then(ret => {
                if (ret.code === 1) {
                    this.cateGroupList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //展示标签
        showtag() {
            this.tagVisible = true;
        },
        queryTagSearch(queryString, cb) {
            let results = []
            this.queryTagParams.name = queryString
            request.get('/common/tag/list', this.queryTagParams).then(ret => {
                if (ret.code === 1) {
                    if (ret.data.list.length > 0) {
                        setTimeout(() => {
                            for (let i of ret.data.list) {
                                i.value = i.name;  //将想要展示的数据作为value
                            }
                            results = ret.data.list
                            cb(results);
                        }, 200);
                    } else {
                        request.post('/common/tag/add', { name: queryString, state: 2 }).then(ret => {
                            if (ret.code == 1) {
                                this.queryTagSearch(queryString, cb);
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    }

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 新增tag
        handleTagSelect(item) {
            this.tagVisible = false;
            this.tagList.push(item)
            let obj = {};
            let newArr = this.tagList.reduce((cur, next) => {
                obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
                return cur;
            }, [])
            this.tagList = newArr
            this.tag = null
        },
        handleAvatarSuccess1(res, file) {
            if (res.code === 1) {
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code === 1) {
                console.log("bg_img handleAvatarSuccess")
                this.$set(this.form, 'bg_img', res.data.uri);
            }
        },
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        toggleBgImg(val) { // 关闭 banner对话框
            this.BgImgVisible = val;
        },
        choiceImg() {
            this.ImgVisible = true
        },
        choiceBgImg() {
            this.BgImgVisible = true
        },
        getLogoImg(url) {// 获取选择的img
            this.$set(this.form, "logo", url);
        },
        getBgImg(url) {// 获取选择的img
            this.$set(this.form, "bg_img", url);
        },


        formatDateFn(date){
           return  formatDate(date)
        },


        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },
        loadData() {
            this.loading = true;
            // 获取banner列表
            request.get('/enterprise/list', this.queryParams).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.ctime = deal.timestamp(val.ctime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },

        //新增
        add() {
            this.mode = 'add';
            this.title = '新增企业';
            this.form = {
                state: 2
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }

        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑企业';
            this.current = row.ID;
            const item = this.pageInfo.list[index];
            let time = [item.ctime, item.etime]
            //处理时间
            request.get("/enterprise/detail", {ID: row.ID}).then(ret => {
                if (ret.code === 1) {
                    this.form=ret.data.detail
                    this.tagList = ret.data.tag
                } else {
                    this.$message.error(  ret.msg);
                }
            });
            this.form = Object.assign({}, item);
            this.$set(this.form, 'time', time);
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }

        },
        genMpQrcode(index, row) {
            request.post("/enterprise/genMpQrocde", {ID: row.ID}).then(ret => {
                console.log(ret)
                if (ret.code === 1) {
                    this.$message.success('生成小程序码成功');
                } else {
                    this.$message.error('生成小程序码失败：' + ret.msg);
                }
            });
        },
        remove(index, row) {
            const item = this.pageInfo.list[index];
            this.form = item;
            this.removeVisible = true;
        },
        // 状态
        state(row, state) {
            if (state === 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            console.log(this.form)
            request.post('/enterprise/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 编辑新增
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let url = this.mode === 'add' ? '/enterprise/add' : '/enterprise/edit';
                        if (this.tagList){
                            let tagId = this.tagList.map(item => item.id)
                            let tagIds = tagId.join(',')
                            this.$set(this.form, 'tag_ids', tagIds)
                        }
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            }
            ;
        },
        // 确定下线
        stateData() {
            request.post('/enterprise/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }

    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
      padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 75px;
    line-height: 75px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 150px;
    height: 75px;
    line-height: 75px;
}
.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
